import React, { useEffect, useState } from 'react'
import axios from "axios"

const SellTest = ({onSelect, onSubmit, setAddTest, item}) => {
    const [data, setAllData] = useState([])
    const [selectedUserId, setSelectedUserId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    
    const svg1 = <svg  className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor" >
      <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"/>
      </svg>

  useEffect(()=> {
    if(data?.length === 0){
      getAlluserData()
    }
  },[data])

  const getAlluserData = async() => {
    try{
      const response = await axios.get(process.env.REACT_APP_BACKENDURL+"/user")
      if(response){
        setAllData(response.data.data)
      }
      else{
        console.log("Something Went Wrong")
      }
    }catch(err){
      console.log(err)
    }
  }

  const handleUserSelect = (event) => {
    const userId = event.target.value;
    setSelectedUserId(userId);
    onSelect(userId);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setIsDropdownOpen(true);
  };

  
  const filteredUsers = data?.filter(user => {
    return (
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone.toString().includes(searchTerm) )
  });

  const closeHandler = () => {
    setAddTest(prevValue => !prevValue)
  }

  const labelClass = 'justify-start font-medium'
  const inputClass = " font-medium border-solid active:border-lime-600 pl-2 mt-2 w-[100%] justify-stretch block border-cyan-500 border-2 rounded-md"

  return (
    <form onSubmit={(e) => { e.preventDefault(); onSubmit(e); }} className='absolute sm:inset-5 w-[90%] h-[420px] sm:w-[600px] sm:h-[400px] bg-white mt-10 px-5 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10'>
            <div className='flex justify-end'>
            <button onClick={closeHandler} className='bg-pink-600 w-6 hover:bg-pink-500 rounded-md text-white'>{svg1}</button>
            </div>
            <label className={labelClass} htmlFor="userDropdown">Search a user</label>
      <input 
        className={inputClass}
        type="text" 
        placeholder="Search users..." 
        value={searchTerm} 
        onChange={handleSearch} 
      />
      <select 
      className={inputClass}
        style={{ height: '50px',marginTop:"30px", overflowY: 'scroll' }}
        id="userDropdown"
        value={selectedUserId}
        onChange={handleUserSelect}
        open={isDropdownOpen ? 'open' : null}
      >
        <option value="">Select a user</option>
        {filteredUsers.map((user) => (
          <option key={user._id} value={user._id}>
            {user.name}, {user.phone}
          </option>
        ))}
      </select>        
            <button className='mt-4 w-full bg-pink-600 hover:bg-pink-500 text-white rounded-lg h-9'>Submit</button>
        </form>
  )
}

export default SellTest